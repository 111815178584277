
import {Component, Vue} from 'vue-property-decorator';
import CustomPageTitle from "../components/CustomPageTitle.vue";

@Component({
    components: {
        CustomPageTitle
    },
})
export default class Home extends Vue {

    get faq() {
        const frequentlyAskedQuestions: Map<string, string> = new Map();
        const faq = Object.values(this.$i18n.t('faq'));
        faq.forEach((v) => {
            frequentlyAskedQuestions.set(v.question, v.answer);
        });
        return frequentlyAskedQuestions;
    }
}
